import Vue from 'vue'
import { createInertiaApp } from '@inertiajs/inertia-vue'
import 'vue-tasty-burgers/dist/vue-tasty-burgers.css'
import {modal} from './modal'

// Configuration.
Vue.config.productionTip = false;

window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

// Directives.
Vue.directive('visible', function(el, binding) {
  el.style.visibility = !!binding.value ? 'visible' : 'hidden';
});

// App.
createInertiaApp({
  resolve: name => require(`./Pages/${name}`),
  setup({ el, App, props }) {
    new Vue({
      mixins: [modal],
      render: h => h(App, props),
    }).$mount(el)
  },
})


// Instance.
/*new Vue({
    el: '#content',
    data: {
        modal: false,

        fullpageOptions: { // fullpage.js
            licenseKey: '1E740EB3-7A334DF6-B63C96A7-A4ADF4D2',
            menu: '#menu',
            scrollOverflow: true,
            verticalCentered: true,
            css3: false,
            normalScrollElements: '.scrollable-content',
        },
        current: {
            details: null,
            thumbs: [],
            images: [],
            index: null,
        },
        eventDetails: {
            details: null,
            thumbs: [],
            images: [],
            index: null,
        },
        gallery: {
            thumbs: [],
            images: [],
            index: null,
        },
        sliderSettings: { // slick carousel.
            "slidesToShow": 3,
            'slidesToScroll': 1,
            'infinite': true,
            'responsive': [
                {
                    'breakpoint': 1280,
                    'settings': {
                        'slidesToShow': 2,
                        'infinite': true,
                    }
                },
                {
                    'breakpoint': 770,
                    'settings': {
                        'slidesToShow': 1,
                        'infinite': true,
                    }
                },
            ]
        },
    },
    mounted () {
        //this.openGallery('http://localhost:3002/gallerie/3')
    },
    methods: {
        // Current details.
        openCurrent(route) {
            axios
                .get(route)
                .then((response) => {
                    this.current.details = response.data.html
                    this.current.images = response.data.images
                    this.current.thumbs = response.data.thumbs
                    this.modal = true
                })
        },
        closeCurrent() {
            this.current.details = null
            this.current.images = []
            this.current.thumbs = []
            this.modal = false
        },

        // Event details.
        openEvent(route) {
            axios
                .get(route)
                .then((response) => {
                    this.eventDetails.details = response.data.html
                    this.eventDetails.images = response.data.images
                    this.eventDetails.thumbs = response.data.thumbs
                    this.modal = true
                })
        },
        closeEvent() {
            this.eventDetails.details = null
            this.eventDetails.images = []
            this.eventDetails.thumbs = []
            this.modal = false
        },

        // Gallery.
        openGallery(route) {

            axios
                .get(route)
                .then((response) => {

                    this.gallery.images = response.data.images
                    this.gallery.thumbs = response.data.thumbs
                    this.modal = true
                })
        },

        closeGallery() {
            this.gallery.images = []
            this.gallery.thumbs = []
            this.modal = false
        },
    },
    watch: {
        modal: function (value) {
            this.$refs.fullpage.api.setAllowScrolling(! value)
        },
        menu: function (value) {
            this.$refs.fullpage.api.setAllowScrolling(! value)
        }
    }

})

let sidebar = document.querySelector('.fp-section.active');

let top = localStorage.getItem("sidebar-scroll");
if (top !== null) {
  sidebar.offsetTop = parseInt(top, 10);
}

window.addEventListener("beforeunload", () => {
  localStorage.setItem("sidebar-scroll", sidebar.offsetTop);
});*/


