<template>
  <overlay-layout
      close-route="/gallerien">

    <h1>{{ gallerieData.gallerie.title }} </h1>

    <gallerie :thumbs="gallerieData.thumbs"
              :images="gallerieData.images"
              :image="gallerieData.image"/>

  </overlay-layout>
</template>

<script>

import OverlayLayout from '../Layouts/OverlayLayout'
import Gallerie from '../Partials/Gallerie'

export default {

  components: {
    Gallerie,
    OverlayLayout,
  },

  props: {
    gallerieData: Object
  },


}

</script>
