<template>
  <page-layout background-image="/images/kontakt.jpg" >
    <div id="kontakt">
      <div>
        <h1>Kontakt</h1>
        <div v-html="content" />
      </div>
    </div>
  </page-layout>
</template>

<script>

import { Link } from '@inertiajs/inertia-vue'
import PageLayout from '../Layouts/PageLayout'

export default {
  components: {
    Link,
    PageLayout,
  },
  props: {
    content: String,
  },
}
</script>

<style lang="scss" scoped>
#kontakt {
  height: 80%;
  display: flex;
  align-items: center;
}
</style>
