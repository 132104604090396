<template>
  <div class="gallerie">

    <vue-gallery :images="images"
                 :index="index"
                 @onclosed="closed"
                 @close="index = null">
    </vue-gallery>

    <slot>
      <div
          v-if="thumbs.length"
          class="image"
          v-for="(image, imageIndex) in thumbs"
          @click="index = imageIndex">

        <img class="thumb" :src="image">
      </div>
    </slot>
  </div>
</template>

<script>

import VueGallery from 'vue-gallery'

export default {

  components: {
    VueGallery
  },

  props: {
    thumbs: Array,
    images: Array,
    image: {
      default: null,
    },
  },

  data () {
    return {
      index: null,
      overflow: null,
    }
  },

  mounted() {

    // Blueimp gallerie sets the overflow property.
    // We need to rememember to overlow value to be able to reset it once the gallery closes.
    this.overflow = document.body.style.overflowY


    this.index = this.image ? parseInt(this.image) : null
  },

  methods: {

    // Gallerie closed. Reset the overflow value.
    closed() {
      document.body.style.overflowY = this.overflow;
    }
  },

  watch: {
    image: function (index) {
      this.index = parseInt(index)
    }
  }
}

</script>

<style lang="scss" scoped>
.gallerie {

  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 .5% 0 -.5%;

  .image {
    flex: 32.3%;
    max-width: 32.3%;
    padding: 0 .5%;
    cursor: pointer;

    img {
      margin-top: 8px;
      vertical-align: middle;
      width: 100%;
      border: 2px solid #fff;

      &:hover {
        border-color: $primary;

        transition: border-color ease .5s;
      }
    }
  }

  @media screen and (max-width: 800px) {
    .column {
      flex: 50%;
      max-width: 50%;
    }
  }

  @media screen and (max-width: 600px) {
    .column {
      flex: 100%;
      max-width: 100%;
    }
  }
}

</style>
