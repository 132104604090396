<template>

  <page-layout background-image="/images/programm.jpg" >

    <div id="programm">

      <div class="currents">
        <div v-for="current in currents"
             class="current">

          <article class="current">

            <details>
              <summary>
                {{ current.title }}
              </summary>
            </details>

            <div class="description" v-html="current.description" />

            <div v-if="current.period"
                 class="time">
              {{ current.period }}
            </div>

            <div v-if="current.details" class="details">
              <Link :href="current.url" preserve-scroll>Details ...</Link>
            </div>

          </article>
        </div>
      </div>


      <div class="events">
        <div class="event"
             v-for="event in events">

          <article class="event">

            <time :datetime="event.iso_date">
              {{ event.short_date }}
            </time>

            <details>
              <summary v-html="event.title"></summary>
            </details>

            <div class="time" v-if="event.time">
              {{  event.time }}
            </div>

            <div class="details" v-if="event.details">
              <Link :href="event.url" :preserve-scroll="true">Details ...</Link>
            </div>

          </article>
        </div>

        <div class="end">
          <p>Weitere folgen ...</p>
        </div>
      </div>
    </div>

  </page-layout>

</template>

<script>

import {Link} from '@inertiajs/inertia-vue'
import PageLayout from '../Layouts/PageLayout'

export default {
  components: {
    Link,
    PageLayout,
  },

  props: {
    currents: Array,
    events: Array,
  },
}
</script>

<style lang="scss" scoped>

#programm {
  margin: 0 auto 0 5rem;

  @include md {
    text-align: center;
    margin: 0 auto;
    max-width: 20rem;
  }
}


.start {
  height: 15vh;
  min-height: 8rem;

  @include md {
    height: 16vh;
  }

  @include sm {
    height: 10vh;
    min-height: 6.5rem;
  }

}

article {

  max-width: 35rem;

  time {
    font-family: 'Passion One', cursive;
    font-size: 1.8rem;
    letter-spacing: .1rem;
    text-transform: uppercase;

    @include sm {
      letter-spacing: .08rem;
      font-size: 1.5rem;
    }

    margin: 0;
    padding: 0;
  }

  padding: 1rem 0;

  summary {
    font-size: 1.6rem;
    line-height: 1.2;
    letter-spacing: .07rem;

    @include sm {
      font-size: 1rem;
    }
  }

  .time {
    padding-top: .3rem;
    font-size: 1.3rem;
  }

  .details {
    padding-top: .3rem;
    font-size: 1.2rem;
    letter-spacing: .04rem;

    a {
      color: $primary;
    }
  }

  &:after {
    content: "";
    position: absolute;
    margin-top: 1rem;
    left: 50%;
    margin-left: -3.5rem;
    height: 0.125rem;
    background: #fff;
    width: 7rem;
  }

  &:last-child {

    &:after {
      background: transparent;
    }
  }

  &.current {
    summary {
      font-family: 'Passion One', cursive;
      font-size: 2.3rem;
      letter-spacing: .01rem;
      text-transform: uppercase;
    }

    .time {
      padding-top: .5rem;
    }
  }

  &.event {

    @include md {
      padding: .5rem 0;
    }

    time {
      @include md {
        //font-size: 1.2rem;
      }
    }

    .time {
      @include md {
        //display: none;
      }
    }

    .details {
      @include md {
        //font-size: 1rem;
      }

    }
  }
}

.end {
  height: 15rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Passion One', cursive;
  font-size: 2rem;
  letter-spacing: .01rem;
  text-transform: uppercase;

  @include xs {
    font-size: 1.3rem;
  }
}
</style>
