<template>
  <page-layout
      background-image="/images/oeffnungszeiten.jpg">
    <div id="oeffnungszeiten">
      <div>
        <h1>Öffnungszeiten</h1>
        <div v-html="content" />
      </div>
    </div>

  </page-layout>
</template>

<script>

import { Link } from '@inertiajs/inertia-vue'
import PageLayout from '../Layouts/PageLayout'

export default {
  components: {
    Link,
    PageLayout,
  },
  props: {
    content: String,
  },
}
</script>

<style lang="scss" scoped>
#oeffnungszeiten {
  height: 80%;
  margin-top: 10%;

  @include sm {
    margin-top: 0;
    height: 80%;
    display: flex;
    align-items: center;
  }
}
</style>
