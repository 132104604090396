<template>
  <overlay-layout
      close-route="/programm">

      <div v-html="eventData.event.details"></div>

      <gallerie :thumbs="eventData.thumbs"
                :images="eventData.images" />

  </overlay-layout>
</template>

<script>

import OverlayLayout from '../Layouts/OverlayLayout'
import Gallerie from '../Partials/Gallerie'

export default {

  components: {
    Gallerie,
    OverlayLayout,
  },

  props: {
    eventData: Object
  },


}

</script>
