<template>
  <div id="overlay">

    <div class="logo">
      <Link :href="closeRoute" preserve-scroll>
        <img src="/images/logo.svg" alt="Wisa Bar">
      </Link>
    </div>

    <Link :href="closeRoute" class="close" preserve-scroll>
      ×
    </Link>

    <main>
      <slot  />
    </main>
  </div>

</template>

<script>

import {Link} from '@inertiajs/inertia-vue'

export default {

  components: {
    Link,
  },

  props: {
    closeRoute: String,
  },

  data() {
    return {
    };
  },

  mounted() {
  },

  methods: {
  },

}
</script>

<style lang="scss" scoped>

#overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-y: auto;

  .logo {
    display: block;
    position: fixed;
    top: 1.5rem;
    left: 1rem;

    img {
      height: 2.5rem;
    }

    @include sm {
      left: 50%;
      transform: translateX(-55%);
      top: 1rem;
    }

    @include xs {
      left: 5%;
      transform: translateX(0%);
      img {
        max-width: 80%;
        height: 2rem;
      }
    }
  }

  .close {
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    cursor: pointer;
    font-size: 120px;
    font-weight: 500;
    line-height: 0;
    padding: 30px;

    @include md {
      padding: 25px;
    }

    @include sm {
      padding: 20px;
    }

  }

  main {
    width: 80%;
    max-width: 64rem;
    margin: 0 auto;
    padding-top: 6rem;
    padding-bottom: 6rem;

    @include sm {
      width: 90%;
      padding-top: 5rem;
      padding-bottom: 5rem;
    }

    div.images {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;

      div.image {
        max-width: 32%;
        padding-right: 1.1%;

        cursor: pointer;
        width: auto;
        margin-bottom: 1%;

        @include sm {
          max-width: 45%;
          padding-right: 5%;
        }

        img {
          width: 100%;
          height: auto;
          border: 2px solid #fff;

          &:hover {
            border-color: $primary;

            transition: border-color ease .5s;
          }
        }
      }
    }


  }

  details {
    summary {
      padding-top: 2rem;
      font-family: 'Passion One', cursive;
      font-size: 2rem;
      letter-spacing: .02rem;
      text-transform: uppercase;
    }
  }

}



</style>
