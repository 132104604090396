<template>
  <page-layout
      background-image="/images/gallerien.jpg"
      main-css-class="main-gallerie">

    <div id="gallerien">

      <div v-for="gallerie in gallerien"
           class="gallerie">

        <h2>{{ gallerie.titel}}</h2>

        <div class="carousel">

          <VueSlickCarousel :ref="'gallerie' + gallerie.id"
                            v-bind="sliderSettings">

            <template #prevArrow="arrowOption">
              <div class="prev">
                <div>
                  &lt;
                </div>
              </div>
            </template>

            <template>
              <Link v-for="(thumb, index) in gallerie.thumbs"
                    key="thumb"
                    :href="gallerie.url + '?image=' + index" :preserve-scroll="true"
                    class="thumb">

                <img :src="thumb">

              </Link>
            </template>

            <template #nextArrow="arrowOption">
              <div class="next">
                <div>
                  &gt;
                </div>
              </div>
            </template>


          </VueSlickCarousel>


        </div>

      </div>

    </div>

  </page-layout>
</template>

<script>

import { Link } from '@inertiajs/inertia-vue'
import PageLayout from '../Layouts/PageLayout'
import VueSlickCarousel from 'vue-slick-carousel'

export default {
  components: {
    Link,
    PageLayout,
    VueSlickCarousel
  },

  props: {
    gallerien: Array,
  },

  data() {
    return {
      sliderSettings: { // slick carousel.
        /*"infinite": true,
        "centerMode": true,
        "centerPadding": "20px",
        "slidesToShow": 1,
        "slidesToScroll": 1,
        "variableWidth": true*/
        arrows: true,
        "slidesToShow": 3,
        "slidesToScroll": 1,
        "initialSlide": 0,
        "infinite": true,
        "responsive": [
          {
            'breakpoint': 660,
            'settings': {
              'slidesToShow': 2,
              'infinite': true,
            }
          },
          {
            'breakpoint': 440,
            'settings': {
              'slidesToShow': 1,
              'infinite': true,
            }
          },
        ]
      },
    }
  },
}
</script>

<style lang="scss" scoped>

@import '../../../node_modules/vue-slick-carousel/dist/vue-slick-carousel.css';

#gallerien {
  width: 60%;
  margin-left: 0;

  @include sm {
    text-align: center;
    margin: 0 auto;
    width: 80%;
  }

  .gallerie {

    &:last-child {
      padding-bottom: 5rem;
    }

    h2 {
      text-align: left;
      line-height: 1.1;
      margin-left: 2.3rem;
      margin-bottom: 0;
      width: 80%;
    }

    .carousel {

      width: 81%;

      @include xs {
        width: 100%;
      }

      @include md {
        width: 100%;
      }

      .slick-slider {

        display: flex;
        justify-content: space-between;
        align-items: center;

        .prev, .next {
          color: #fff;
          background-color: transparent;
          border: none;
          cursor: pointer;

          display: flex;
          align-items: center;

          transition: color 0.5s ease;

          > div {
            height: 8.5rem;
            font-size: 4.5rem;
            font-weight: 700;
            font-family: 'Dosis', sans-serif;
          }

          &:hover {
            color: $primary;
          }

        }

        .prev {
          padding-right: .5rem;
        }

        .next {
          padding-left: .5rem;
        }


        .slick-list {

          .slick-track {

            .slick-slide {

              > div {
                width: 100%;
              }

              &:focus {
                outline: none;
              }

              .thumb {
                display: block;
                margin: 0 auto 0 0;
                position: relative;
                cursor: pointer;

                .mobile-click-overlay {
                  position: absolute;
                  left: 0;
                  top: 0;
                  bottom:0;
                  right: 0;
                  background-color: transparent;
                  width: 100%;
                  height: 100%;
                }

                img {
                  width: 96%;
                  margin: 0 auto 0 0;
                  border: .05rem solid #fff;
                  transition: border-color ease .5s;
                }

                @include sm {

                  width: 80%;

                  img {
                    width: 98%;
                    margin: 0 auto;
                  }
                }
              }

              &:hover {

                h2 {
                  color: $primary;
                }

                img {
                  border-color: $primary;
                }
              }

            }
          }
        }
      }
    }
  }
}

</style>

<style lang="scss">
  .main-gallerie {

    @include md {
      margin-left: 0 !important;
    }

    @include sm {
      margin-left: auto !important;
    }
  }
</style>
