<template>
  <div>
    <div id="page">

      <header v-visible="! $root.modalComponent">
        <div class="slogan">
          Die Musikbar mit Kultur
        </div>

        <div class="logo">
          <img src="/images/logo.svg" alt="Wisa Bar">
        </div>

      </header>

      <main :class="[
          modalOpening ? 'animate__animated animate__fadeOut' : '' ,
          modalClosing ? 'animate__animated animate__fadeIn' : '',
          mainCssClass]"
            v-visible="! $root.modalComponent">
        <slot  />
      </main>

      <page-menu v-visible="! $root.modalComponent"/>

      <transition name="fade">
        <Component
            v-bind="$page.props"
            v-if="$root.modalComponent"
            :is="$root.modalComponent"
        />
      </transition>

    </div>
  </div>

</template>

<script>

import PageHeader from '../Partials/PageHeader'
import PageMenu from '../Partials/PageMenu'

export default {

  components: {
    PageHeader,
    PageMenu
  },

  props: {
    backgroundImage: String,
    mainCssClass: String,
  },

  data() {
    return {
      animate: false,

    };
  },

  mounted() {
    this.animate = true
    this.setBackgroundImage()

  },

  methods: {
    setBackgroundImage() {
      document.body.style.backgroundImage = "linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url('" + this.backgroundImage + "')"
    }
  },

  computed: {

    modalOpening() {
      return this.$root.modalComponent
    },

    modalClosing() {
      return ! this.$root.modalComponent
    },

    mainClass() {

    }
  },

  watch: {

    // Hide scrollbars when in "modal" mode.
    '$root.modalComponent': {
      handler: function(val) {
        document.body.style.overflowY = (false === val ? 'auto' : 'hidden')
      },
      deep: true,
      immediate: true
    }
  }

}
</script>

<style lang="scss" scoped>
.page-enter-active,
.page-leave-active {
  transition: all .5s;
}

.page-enter,
.page-leave-active {
  opacity: 0;
}

#page {

  display: flex;
  flex-flow: column;
  height: 100%;

  header {

    width: 100%;
    text-align: center;
    margin: 1rem 0;
    flex: 0 1 auto;

    .slogan {
      font-family: 'Passion One', cursive;
      font-size: 1rem;
      letter-spacing: .06rem;
      padding-bottom: .75rem;
      color: $primary;

      @include sm {
        display: none;
      }

      @include md {
        padding-bottom: .33rem;
      }

    }

    .logo {
      img {
        max-width: 50%;
      }
    }
  }

  main {
    text-align: left;
    flex: 1 1 auto;
    margin: 0 1rem;

    h1 {
      margin: 0;
    }

    @include md {
      height: 100%;
      text-align: center;
      margin: 0 auto;
      max-width: 80%;
      display: flex;
      justify-content: center;
    }

  }

}

</style>
