<template>
  <div id="menu">

    <transition name="fade">
      <div class="desktop" >
        <ul>
          <li v-for="item in items" :key="item.route"
              :class="{'active': $page.url === item.url}">
            <Link :href="item.url">{{ item.name }}</Link>
          </li>
        </ul>
      </div>
    </transition>

    <transition name="fade">
      <div class="mobile" v-if="menu">
        <ul>
          <li v-for="item in items" :key="item.route"
              :class="{'active': $page.url === item.url}">
            <Link :href="item.url">{{ item.name }}</Link>
          </li>
        </ul>
      </div>
    </transition>

    <div class="mobile-menu-toggler">
      <tasty-burger-button
          :type="'collapse'"
          :active="menu"
          :color="'#fff'"
          :size="'m'"
          :active-color="'#fff'"
          @toggle="toggleMenu"></tasty-burger-button>
    </div>

  </div>
</template>

<script>

import {Link} from '@inertiajs/inertia-vue'
import {TastyBurgerButton} from 'vue-tasty-burgers'

export default {
  components: {
    Link,
    TastyBurgerButton,
  },

  data() {
    return {

      // Menu items.
      items: [
        {
          name: 'Programm',
          url: '/programm'
        },
        {
          name: 'Öffnungszeiten',
          url: '/oeffnungszeiten'
        },
        {
          name: 'Kontakt',
          url: '/kontakt'
        },
        {
          name: 'Gallerien',
          url: '/gallerien'
        }
      ],

      // Menu.
      menu: false,

    }
  },

  methods: {
    toggleMenu(active) {
      this.menu = active
    },
  }
}

</script>

<style lang="scss" scoped>
#menu {

  .desktop {
    display: block;
    position: fixed;
    top: 50%;
    right: 5%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);

    @include sm {
      display: none;
    }
  }

  .mobile {

    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999;
    background-color: rgba(0,0,0, .8);
    -ms-transform: translateY(0);
    transform: translateY(0);
    align-items: center;
    justify-content: center;

    @include sm {
      display: flex;
    }
  }


  ul {

    list-style-type: none;
    text-align: center;
    padding-left: 0;

    li {
      a {
        font-family: 'Passion One', cursive;
        font-size: 2.5rem;
        letter-spacing: .06rem;
        text-transform: uppercase;

        @include md {
          font-size: 2rem;
        }

        @include sm {
          font-size: 2rem;
        }
      }

      &.active {
        a {
          color: $primary;
        }
      }

    }
  }

  .mobile-menu-toggler {

    display: none;

    @include sm {
      z-index: 1000;
      opacity: 1;
      display: flex;
      position: fixed;
      top: 1.5rem;
      right: 1.5rem;

      .hamburger {
        &:hover, &:focus {
          opacity: 1;
        }

        &:hover {
          color: $primary;
        }
      }

    }

  }

  #close-menu {
    display: none;
  }


}
</style>


