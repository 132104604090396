<template>
  <overlay-layout
      close-route="/programm">

      <div v-html="currentData.current.details"></div>

      <gallerie :thumbs="currentData.thumbs"
                :images="currentData.images" />

  </overlay-layout>
</template>

<script>

import OverlayLayout from '../Layouts/OverlayLayout'
import Gallerie from '../Partials/Gallerie'

export default {

  components: {
    Gallerie,
    OverlayLayout,
  },

  props: {
    currentData: Object
  },


}

</script>
