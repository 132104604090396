<template>
  <header>

    <div class="mobile-menu-toggler">
      <tasty-burger-button
          :type="'collapse'"
          :active="menu"
          :color="'#fff'"
          :size="'m'"
          :active-color="'#fff'"
          @toggle="toggleMenu"></tasty-burger-button>
    </div>

  </header>
</template>

<script>

import {TastyBurgerButton} from 'vue-tasty-burgers'

export  default {

  components: {
    TastyBurgerButton
  },

  data: () => {
    return {
      menu: false,
    }
  },

  methods: {
    toggleMenu(active) {
      this.menu = active
    },
  }
}
</script>

<style lang="scss" scoped>
header {
  position: fixed;
  top: 2%;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  z-index: 9999;

  &.modal {
    left: 0;
    padding-left: 1rem;
    transform: translateX(0%);
  }

  @include md {

    //width: 60%;
    top:1rem;

    .logo {
      img {
        width: 60%;
      }
    }
  }

  @include sm {
    width: 80%;

    .logo {
      width: 100%;

      img {
        max-width: 100%;
      }
    }
  }

  @include xs {
    width: 50%;

    .logo {
      width: 100%;

      img {
        width: 100% !important;
      }
    }
  }

  .logo.modal {
    text-align: left;

    img {
      width: 40%;
    }

    @include sm {

      img {
        width: 60%;
      }
    }

    @include xs {

      img {
        width: 100% !important;
      }

    }

  }



  .mobile-menu-toggler {

    display: none;

    .hamburger {
      opacity: 1;
    }

    @include xs {
      margin-top: 1rem;
    }

    @include sm {
      display: block;
    }
  }


}
</style>
